@font-face {
  font-family: 'Didot';
  src: url('../fonts/Didot Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'CottonCloud';
  src: url('../fonts/Cotton Cloud.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.hero-content {
  font-family: 'AmazonPalafita', sans-serif;
}

.hero {
  position: relative;
  background-image: url('../images/Gif.gif');
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.hero-content {
  z-index: 1;
  text-align: center;
  color: white;
  margin-top: auto;
  margin-bottom: 50px;
}

.additional-buttons {
  position: absolute; 
  top: 20px; 
  left: 50%; 
  transform: translateX(-50%);
  display: flex;
  gap: 10px; 
  z-index: 2; 
}

.btn {
  position: relative;
  width: 60px; 
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: rgba(128, 128, 128, 0.3); 
  padding: 5px; 
  transition: transform 0.3s, box-shadow 0.3s;
}

.btn:hover {
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
}

.image-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: white;
}

.image-btn img {
  width: 100%; 
  height: 100%; 
  object-fit: cover;
  border-radius: 50%;
}

.contract-address {
  font-size: 16px; /* Adjusted for mobile */
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
  padding: 8px 16px; /* Adjust padding for smaller size */
  border-radius: 8px; 
  display: inline-block;
  margin-top: 0; 
  margin-bottom: 20px; 
  color: #ffffff;
  position: relative;
  top: -60px; 
  overflow: hidden;
  animation: waveBackground 6s infinite linear;
  text-shadow: 0 0 5px rgba(0, 255, 255, 0.8), 0 0 10px rgba(0, 255, 255, 0.5);
}

.contract-address:hover {
  background: rgba(0, 0, 0, 0.7);
}

@keyframes waveBackground {
  0% {
    background: linear-gradient(135deg, rgba(0, 150, 255, 0.5) 25%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 150, 255, 0.5) 75%);
    background-size: 200% 200%;
  }
  100% {
    background-position: 100% 100%;
  }
}

.contract-address::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  opacity: 0.2;
  filter: blur(5px);
  animation: waterReflection 4s infinite linear;
}

@keyframes waterReflection {
  0% {
    transform: translateX(-100%) translateY(0) skewX(-10deg);
  }
  100% {
    transform: translateX(100%) translateY(0) skewX(-10deg);
  }
}

.contract-address span {
  display: inline-block;
  position: relative;
  animation: waveText 2s infinite ease-in-out;
}

@keyframes waveText {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px);
  }
}

.fish {
  position: absolute;
  width: 50px;
  height: auto;
  pointer-events: none;
}

/* Mobile adjustments */
@media (max-width: 767px) {
  .contract-address {
    font-size: 14px; /* Smaller font size for mobile */
    padding: 5px 10px; /* Smaller padding */
    top: -40px; /* Adjust the position on mobile */
  }

  .salty-boy {
    font-size: 24px; /* Smaller font size for mobile */
    top: 25%; /* Adjust position to fit better on smaller screens */
  }
}

/* Responsive styles for larger screens */
@media (min-width: 768px) {
  .contract-address {
    font-size: 18px; 
    padding: 15px 30px;
    top: -80px; 
  }

  .btn {
    width: 70px;
    height: 70px;
  }

  .image-btn img {
    width: 80%;
    height: 80%;
  }
}

@media (min-width: 1024px) {
  .contract-address {
    font-size: 28px; 
    padding: 20px 40px;
    top: -120px; 
  }

  .btn {
    width: 80px;
    height: 80px;
  }
}

.salty-boy {
  font-family: 'CottonCloud', sans-serif;
  font-size: 40px; /* Adjusted default size */
  color: #ffffff;
  text-align: center;
  position: absolute;
  top: 20%; 
  left: 50%; 
  transform: translateX(-50%);
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(255, 255, 255, 0.5); 
  animation: cloudMove 20s infinite ease-in-out; 
  white-space: nowrap;
}

/* Animation to create a floating cloudy effect */
@keyframes cloudMove {
  0% {
    transform: translate(-50%, 0) scale(1);
  }
  25% {
    transform: translate(-45%, -10px) scale(1.05);
  }
  50% {
    transform: translate(-50%, 5px) scale(1.1);
  }
  75% {
    transform: translate(-55%, -5px) scale(1.05);
  }
  100% {
    transform: translate(-50%, 0) scale(1);
}
}
/* Adjustments for desktop */
@media (min-width: 1024px) {
  .salty-boy {
    font-size: 80px; /* Increase font size on desktop */
    top: 15%; /* Move higher on the screen */
  }
}
